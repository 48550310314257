import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { Link } from "gatsby"
import cv from "../../static/fernandocv.pdf"

class ContactPage extends Component {
  render() {
    let contactData = this.props.data.allContactJson.nodes[0]
    return (
      <Layout page={"contact"}>
        <h1 className="title">Hire me</h1>
        <div className="text">
         Converting abstract ideas into beautiful designs is my passion. Please get in touch. 
        </div>
        <div className="text">
            <a href={cv} target="_blank" className="download-cv">Download CV</a>
          </div>
        <div className="team-grid-container">
          <div className="team-grid-item-left">
            <img
              src="images/title.svg"
              alt="Name"
              className="info-icon"
            ></img>
            Fernando Pastorino
            <br />
            <span>
              <img
                src="images/title.svg"
                alt="Title"
                className="info-icon"
              ></img>
              Graphic Designer
            </span>
            <br />
            <span>
              <img
                src="images/mail.svg"
                alt="Mail"
                className="info-icon"
              ></img>
              hi@fernandopastorino.com
            </span>
          </div>
          <div className="team-grid-item-right">
            <img src="images/photo.jpg" alt="Team" className="team-icon"></img>
          </div>
        </div>

      </Layout>
    )
  }
}
export default ContactPage

export const query = graphql`
  query {
    allContactJson {
      nodes {
        id
        title
        description
        text
        email
        phone
        twitter
        email_icon
        phone_icon
        twitter_icon
      }
    }
  }
`
